import styles from "./index.module.scss";
import React, { useMemo, useState } from "react";
import _ from "lodash";
import Image from "../image";
import classnames from "classnames";
import ResizeObserver from "react-resize-observer";
import { navigateTo } from "../../utils/location";
import BranchDetails from "./BranchDetails";
import Button from "../Button";
import PencilIcon from "../icons/Pencil.svg";
import InfoIcon from "../icons/Info.svg";
import BackgroundImageExtrnalLink from "../BackgroundImageExtrnalLink";
import AllergensInformation from "../AllergensInformation";
import LoyaltyStats from "./LoyaltyStats";
import { SERVING_OPTION } from "../../utils/constants";
import { Hidden, Box, Collapse } from "@material-ui/core";
import {
  getAppMedia,
  getAppMediaPreview,
  getAppMediaSrcSet,
  mediaTypes,
} from "../../utils/media";
import ExpandMore from "../expand-more";

export default function({
  T,
  appStyles = {},
  branch,
  displayLoyaltyProfile,
  selectedServingOption,
  classNames,
  isSSR,
  onResize,
  moreThanOneBranchAvailable,
  allergens,
  tableCode,
  openTableCodeModal,
  user,
}) {
  const {
    Order: { Title } = {},
    Card = {},
    MenuHeader = {},
    links = {},
  } = appStyles;

  const [showLocationDetails, setShowLocationDetails] = useState();

  const toggleLocationDetails = () =>
    setShowLocationDetails(!showLocationDetails);

  const {
    imageKey: webAppHeaderKey,
    imagePreview: webAppHeaderPreview,
  } = useMemo(
    () => getAppMedia(mediaTypes.webAppHeader, appStyles) || {},

    [appStyles],
  );
  const {
    imageKey: webMobileAppHeaderKey,
    imagePreview: webMobileAppHeaderPreview,
  } = useMemo(
    () => getAppMedia(mediaTypes.webMobileAppHeader, appStyles) || {},
    [appStyles],
  );

  const mobileHeader = {
    mediaKey: webMobileAppHeaderKey || webAppHeaderKey,
    imagePreview: webMobileAppHeaderPreview || webAppHeaderPreview,
    mediaType: webMobileAppHeaderKey
      ? mediaTypes.webMobileAppHeader
      : mediaTypes.webAppHeader,
  };

  const desktopHeader = {
    mediaKey: webAppHeaderKey || webMobileAppHeaderKey,
    imagePreview: webAppHeaderPreview || webMobileAppHeaderPreview,
    mediaType: webAppHeaderKey
      ? mediaTypes.webAppHeader
      : mediaTypes.webMobileAppHeader,
  };

  return (
    <div className={classnames(..._.castArray(classNames), styles.MenuHeader)}>
      {!isSSR && <ResizeObserver onResize={onResize} />}
      {links.nutrition && (
        <BackgroundImageExtrnalLink
          appStyles={appStyles}
          to={links.nutrition}
          style={{ top: 18 }}
          classNames={styles.HideOnMobile}
        >
          <InfoIcon />
          {T("See our nutrition facts")}
        </BackgroundImageExtrnalLink>
      )}

      <div
        className={classnames(
          styles.LocationImageWrapper,
          !desktopHeader.mediaKey && styles.BranchHeaderSizes,
        )}
      >
        <div
          className={classnames(
            styles.MenuHeaderInfoPanel,
            appStyles.rtl && styles.RTL,
          )}
        >
          {user.loggedIn && displayLoyaltyProfile && (
            <LoyaltyStats user={user} T={T} appStyles={appStyles} />
          )}
          <AllergensInformation
            T={T}
            appStyles={appStyles}
            allergens={allergens}
          />
        </div>
        {!isSSR && (
          <div
            className={classnames(
              !appStyles.noMenuHeaderGradientMask && styles.Shadow,
              appStyles.rtl && styles.RTL,
            )}
          >
            <Hidden xsDown implementation="css">
              <div className={classnames(styles.LocationImageCaption)}>
                <div>
                  <div className={styles.HeaderDesktop}>
                    <div
                      style={{
                        ...MenuHeader,
                        display: "flex",
                        alignItems: "flex-end",
                      }}
                    >
                      {T(selectedServingOption.servingOptionTag)}{" "}
                      {selectedServingOption.type === SERVING_OPTION.SITDOWN ||
                      selectedServingOption.type === SERVING_OPTION.DROP_OFF
                        ? T("at")
                        : T("from")}{" "}
                      {branch.name}
                    </div>

                    <Button
                      slim
                      centered
                      linkStyle
                      appStyles={appStyles}
                      style={{ color: "white", paddingLeft: 30 }}
                      onClick={() => navigateTo("/find-location")}
                    >
                      {!appStyles.noIcons && <PencilIcon />}
                      <div
                        style={{
                          alignSelf: "flex-end",
                          ...(!_.get(appStyles, "Button.textShadow") && {
                            color: "white",
                          }),
                          ...(appStyles.rtl
                            ? { paddingRight: 6 }
                            : { paddingLeft: 6 }),
                        }}
                      >
                        {T("Change")}
                      </div>
                    </Button>
                  </div>
                  {tableCode && (
                    <div className={styles.TableCodeHeader}>
                      <div
                        style={{
                          ...MenuHeader,
                          display: "flex",
                          alignItems: "flex-end",
                          fontSize: "1.3rem",
                        }}
                      >
                        {`${T("Table Chosen:")}  ${tableCode}`}
                      </div>
                      <div>
                        <Button
                          slim
                          centered
                          linkStyle
                          appStyles={appStyles}
                          style={{
                            color: "white",
                            paddingLeft: 30,
                            alignItems: "center",
                          }}
                          onClick={openTableCodeModal}
                        >
                          <PencilIcon />
                          <div
                            style={{
                              alignSelf: "flex-end",
                              fontSize: "1.3rem",
                              ...(appStyles.rtl
                                ? { paddingRight: 6 }
                                : { paddingLeft: 6 }),
                            }}
                          >
                            {T("Change")}
                          </div>
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
                <BranchDetails
                  T={T}
                  appStyles={appStyles}
                  branch={branch}
                  classNames={styles.BranchDetailsDesktop}
                  color="white"
                  moreThanOneBranchAvailable={moreThanOneBranchAvailable}
                />
              </div>
            </Hidden>
          </div>
        )}

        {mobileHeader.mediaKey && (
          <Hidden smUp implementation="css">
            <Image
              mediaKey={mobileHeader.mediaKey}
              mediaType={mobileHeader.mediaType}
              sizes="768px"
              imagePreview={mobileHeader.imagePreview}
              wrapperStyle={isSSR ? {} : { height: 175 }}
            />
          </Hidden>
        )}
        {desktopHeader.mediaKey && (
          <Hidden xsDown implementation="css">
            <Image
              mediaKey={desktopHeader.mediaKey}
              mediaType={desktopHeader.mediaType}
              sizes="1920px"
              imagePreview={desktopHeader.imagePreview}
              wrapperStyle={{ height: 300 }}
            />
          </Hidden>
        )}

        {mobileHeader.mediaKey && (
          <Hidden smUp implementation="css">
            <div
              className={classnames(
                styles.LocationImageCaption,
                appStyles.rtl && styles.RTL,
              )}
              style={Title}
            >
              <div style={{ flexGrow: 1, display: "flex" }}>
                {T(selectedServingOption.servingOptionTag)}{" "}
                {selectedServingOption.type === "sitdown" ? T("at") : T("From")}{" "}
                {branch.name}
                {tableCode && (
                  <div onClick={openTableCodeModal}>
                    <span>{`: ${T("table")} ${tableCode}`}</span>{" "}
                    <PencilIcon
                      className={styles.PencilIconMobile}
                      style={
                        appStyles.RTL ? { paddingRight: 6 } : { paddingLeft: 6 }
                      }
                    />
                  </div>
                )}
              </div>
              <Box marginRight={1}>
                <ExpandMore
                  expanded={showLocationDetails}
                  handleExpandClick={toggleLocationDetails}
                />
              </Box>
            </div>
          </Hidden>
        )}
      </div>
      {!isSSR && (
        <Hidden smUp implementation="css">
          <Collapse in={showLocationDetails}>
            <BranchDetails
              T={T}
              appStyles={appStyles}
              branch={branch}
              style={{
                ...Card,
                borderRadius: 0,
                fontSize: "0.625rem",
                height: "auto",
              }}
              moreThanOneBranchAvailable={moreThanOneBranchAvailable}
            />
          </Collapse>
        </Hidden>
      )}
    </div>
  );
}
