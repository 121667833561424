import React from "react";
import ResizeObserver from "react-resize-observer";
import classnames from "classnames";
import ErrorBlock from "../ErrorBlock";
import LocationClosedMessage from "../LocationClosedMessage";
import styles from "./index.module.scss";

export default ({
  T,
  appStyles,
  isBranchNotAvailable,
  checkoutOpen,
  branchName,
  currentBranchOpeningTime,
  pickupTimes,
  onRetryClicked,
  onResize,
  hasDelayedOrder,
  locationClosedMessage,
  selectedServingOptionType,
}) => {
  return (
    <div
      style={{ position: "fixed", zIndex: 2, width: "100%" }}
      className={classnames(
        styles.ResponsiveHeader,
        checkoutOpen && styles.ResponsiveHeaderCheckoutOpen,
        appStyles.rtl && styles.RTL,
        hasDelayedOrder && styles.WarningMessage,
      )}
    >
      <ErrorBlock warning={hasDelayedOrder} appStyles={appStyles}>
        <LocationClosedMessage
          T={T}
          appStyles={appStyles}
          isBranchNotAvailable={isBranchNotAvailable}
          branch={branchName}
          currentBranchOpeningTime={currentBranchOpeningTime}
          pickupTimes={pickupTimes}
          appStyles={appStyles}
          onRetryClicked={onRetryClicked}
          hasDelayedOrder={hasDelayedOrder}
          locationClosedMessage={locationClosedMessage}
          selectedServingOptionType={selectedServingOptionType}
        />
      </ErrorBlock>
      <ResizeObserver onResize={onResize} />
    </div>
  );
};
