import React from "react";
import { get } from "lodash";
import styles from "./LoyaltyStats.module.scss";

export default class extends React.Component {
  render() {
    const { T, appStyles, user, classNames } = this.props;
    const { Card = {} } = appStyles;
    const points = get(
      user,
      "loyaltyProfile.data.rewardPointBalance.balance",
      "-",
    );
    const visitCount = get(user, "loyaltyProfile.data.visitCount", "-");

    return (
      <div className={styles.LoyaltyProfileContainer} style={{ ...Card }}>
        <div className={styles.UserStats}>
          {!appStyles.hideVisitCount && (
            <>
              <div className={styles.StatRecord}>
                <div className={styles.StatValue}>{visitCount}</div>
                <div className={styles.StatLabel}>{T("Visits")}</div>
              </div>
              <div className={styles.Divider} />
            </>
          )}
          <div className={styles.StatRecord}>
            <div className={styles.StatValue}>{points}</div>
            <div className={styles.StatLabel}>{T("Points")}</div>
          </div>
        </div>
      </div>
    );
  }
}
