import React from 'react';
import classnames from 'classnames';
import Button from '../Button';
import styles from './index.module.scss';

export default ({ T, appStyles, onClick, style }) => (
  <Button
    classNames={classnames(styles.PromoCodeMobileButton, appStyles.rtl && styles.RTL)}
    appStyles={appStyles}
    onClick={onClick}
    centered
    slim
    style={style}
  >
    {T('Add a promo code')}
  </Button>
);
