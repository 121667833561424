import React from "react";
import { get } from "lodash";
import AppContainer from "../AppContainer";
import Button from "../Button";
import MenuItem from "../Item";
import styles from "./index.module.scss";

export default function(props) {
  const {
    T,
    appStyles,
    onClose,
    item,
    image,
    currencySymbol,
    onItemClicked = _.noop,
    prices,
    allergens,
    defaultItemImage = null,
  } = props;
  const [loading, setLoading] = React.useState();
  const onClick = (e) => {
    setLoading(true);
    onItemClicked(e);
  };

  return (
    <AppContainer.CenteredColumn className={styles.CheckDealOrItemNotice}>
      <h3 style={{ padding: 26 }}>
        {item
          ? T("Check out this item!")
          : T(
              "Unfortunately the item is not available in this location/service option",
            )}
      </h3>
      {item ? (
        <React.Fragment>
          <MenuItem
            T={T}
            appStyles={appStyles}
            isMenuItem
            key={item.id}
            description={item.description}
            title={item.name}
            onClick={onClick}
            imageKey={item.imageKey}
            imagePreview={item.imagePreview}
            defaultImage={defaultItemImage}
            classNames={styles.CategoryItem}
            loading={loading}
            imageObjectFit={{
              ...(item.imageObjectFit && {
                objectFit:
                  item.imageObjectFit === "fit"
                    ? "contain"
                    : item.imageObjectFit,
              }),
            }}
            allergens={allergens}
            prices={prices}
            currencySymbol={currencySymbol}
          />
          <Button
            appStyles={appStyles}
            onClick={onClick}
            centered
            style={{ marginTop: 16 }}
          >
            {T("Go to item!")}
          </Button>
          <Button
            appStyles={appStyles}
            onClick={onClose}
            centered
            style={{ marginTop: 16 }}
            linkStyle
          >
            {T("Next time")}
          </Button>
        </React.Fragment>
      ) : (
        <Button
          appStyles={appStyles}
          onClick={onClose}
          centered
          style={{ marginTop: 16 }}
        >
          {T("Close")}
        </Button>
      )}
    </AppContainer.CenteredColumn>
  );
}
