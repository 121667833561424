import React from "react";
import { map, isEmpty } from "lodash";
import RichText from "../rich-text";

import styles from "./index.module.scss";

export default function({ menuNotices }) {
  if (isEmpty(menuNotices)) {
    return null;
  }

  return (
    <small>
      {map(menuNotices, ({ message }) => (
        <ul>
          <li className={styles.MenuNotice}>
            <RichText>{message}</RichText>
          </li>
        </ul>
      ))}
    </small>
  );
}
